<!-- Header - Only available when non running inside a frame -->
@if (!configService.onInternetBankRoute) {
  <spb-header></spb-header>
}

<!-- Normal content -->
<router-outlet></router-outlet>

<!-- Footer - Only available when non running inside a frame -->
@if (!configService.onInternetBankRoute) {
  <spb-footer
    (logoutUser)="configService.logout()"
    [license]="true"
    [token]="(configService.accessToken$ | async)"
    [version]="version"
    copyrightYears="2024"
  ></spb-footer>
}