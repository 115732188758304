import {inject} from '@angular/core'
import {Router} from '@angular/router'
import {ConfigService} from '../services/config.service'

export const routeResolver =
  (service: ConfigService, router: Router, window: Window):
    () => void => {
    return () => {
      const location = window.location

      // Identify if the app is running inside specific routes
      const onInternalRoute = location.hostname.includes('internal')
      const onSparaRoute = location.hostname.includes('spara')


      service.onSparaRoute = onSparaRoute
      service.onInternalRoute = onInternalRoute
      if (onInternalRoute) {
        router.navigate(['/admin']).then()
      }
    }
  }

export const internetbankenResolver = () => {
  const service = inject(ConfigService)
  service.onInternetBankRoute = true
}

